<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-card-text>
        <slot name="title"/>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <slot name="header"/>
          </v-card-text>
          <slot name="content"/>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ListTemplate'
}
</script>

<style scoped>

</style>
